<template>
  <div class="ssidebar">
    <div class="ssidebar_games scroll-hidden">
      <div class="ssidebar_games_section ssidebar-nav">
        <h5>Casino</h5>
        <div
          class="ssidebar-nav_item"
          v-tippy="{
            content: 'Live Games',
            arrow: true,
            placement: 'right',
            theme: 'purple',
            distance: 80,
          }"
        >
          <NuxtLink :to="localePath(`/casino/live`)" class="ssidebar-nav_item__link" exact-active-class="ssidebar-nav_item--active">
            <FontIcon icon="live" size="20" />
          </NuxtLink>
        </div>
        <div class="ssidebar-nav_item" v-tippy="{ content: 'Slots', arrow: true, placement: 'right', theme: 'purple' }">
          <NuxtLink :to="localePath(`/casino/slots`)" class="ssidebar-nav_item__link" exact-active-class="ssidebar-nav_item--active">
            <FontIcon icon="slots" size="20" />
          </NuxtLink>
        </div>
      </div>
      <div class="ssidebar_games_section ssidebar-nav">
        <h5>Trix Games</h5>
        <div
          v-tippy="{
            content: game.title,
            arrow: true,
            placement: 'right',
            theme: 'purple',
          }"
          v-for="(game, idx) in games"
          :key="`nav-game-${idx}`"
          class="ssidebar-nav_item"
        >
          <NuxtLink :to="localePath(game.route)" class="ssidebar-nav_item__link" exact-active-class="ssidebar-nav_item--active">
            <FontIcon :icon="game.icon" size="20" />
          </NuxtLink>
        </div>
      </div>
    </div>
    <div class="ssidebar-nav">
      <!-- 
      <div class="ssidebar-nav_item"
        v-tippy="{ content: 'Rakeback', arrow: true, placement: 'right', theme: 'purple' }">
      <button class="ssidebar-nav_item__link" @click.prevent="$vfm.show('rakeback')">
            <img src="/images/icons/rakeback.svg" alt="" />
          </button>
      </div> -->
      <div
        v-if="canCreateGiveaway"
        class="ssidebar-nav_item"
        v-tippy="{
          content: $t('header.giveaway'),
          arrow: true,
          placement: 'right',
          theme: 'purple',
        }"
      >
        <button class="ssidebar-nav_item__link" @click.prevent="$vfm.show('giveaway_create')">
          <img src="/images/icons/giveaway.svg" alt="" width="20" />
        </button>
      </div>
      <div
        class="ssidebar-nav_item"
        v-tippy="{
          content: $t('header.tournaments'),
          arrow: true,
          placement: 'right',
          theme: 'purple',
        }"
      >
        <NuxtLink :to="localePath(`/tournaments`)" class="ssidebar-nav_item__link">
          <img src="/images/icons/cup.svg" alt="" />
        </NuxtLink>
      </div>
      <div
        class="ssidebar-nav_item"
        v-tippy="{
          content: $t('header.referral'),
          arrow: true,
          placement: 'right',
          theme: 'purple',
        }"
      >
        <NuxtLink :to="localePath(`/refs`)" class="ssidebar-nav_item__link">
          <img src="/images/icons/affiliate.svg" alt="" />
        </NuxtLink>
      </div>
      <!-- 
      <div class="ssidebar-nav_item"
        v-tippy="{ content: $t('header.tournaments'), arrow: true, placement: 'right', theme: 'purple' }">
      <NuxtLink :to="localePath(`/tournaments`)" class="ssidebar-nav_item__link">
            <img src="/images/icons/cup.svg" alt="" />
          </NuxtLink>
      </div>
      <div class="ssidebar-nav_item"
        v-tippy="{ content: $t('header.bonuses'), arrow: true, placement: 'right', theme: 'purple' }">
      <NuxtLink :to="localePath(`/bonus`)" class="ssidebar-nav_item__link">
            <img src="/images/icons/promo.svg" alt="" />
          </NuxtLink> 
      </div>-->
      <div class="ssidebar-nav_item" v-tippy="{ content: 'FAQ', arrow: true, placement: 'right', theme: 'purple' }">
        <NuxtLink :to="localePath(`/faq`)" class="ssidebar-nav_item__link">
          <img src="/images/icons/faq.svg" alt="" />
        </NuxtLink>
      </div>
      <div
        class="ssidebar-nav_item"
        v-tippy="{
          content: $t('header.support'),
          arrow: true,
          placement: 'right',
          theme: 'purple',
        }"
      >
        <NuxtLink :to="localePath(`/support`)" class="ssidebar-nav_item__link">
          <img src="/images/icons/support.svg" alt="" />
        </NuxtLink>
      </div>
      <div class="ssidebar-nav_item">
        <VColorMode vertical />
      </div>
    </div>
  </div>
</template>

<script>
import { directive } from "vue-tippy";

export default {
  directives: {
    tippy: directive,
  },
};
</script>

<script setup>
import { ref } from "vue";

import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";

const localePath = useLocalePath();

const store = useStore();
const { dark } = storeToRefs(store);

const games = [
  {
    title: "Tower",
    route: "/tower",
    icon: "tower",
    key: "tower",
    sort: 99,
  },
  {
    title: "Money Train",
    route: "/mainx",
    icon: "bonusBuy",
    key: "bonus-buy",
    sort: 2,
  },
  {
    title: "Bonus Dice",
    route: "/dice/bonus",
    icon: "dice",
    key: "bd",
    sort: 7,
  },
  {
    title: "Classic Dice",
    route: "/dice",
    icon: "dice",
    key: "cd",
    sort: 9,
  },
  {
    title: "MegaWheel",
    route: "/megawheel",
    icon: "wheel",
    key: "mw",
    sort: 5,
  },
  {
    title: "Bubbles",
    route: "/bubbles",
    icon: "bubbles",
    key: "bb",
    sort: 6,
  },
  {
    title: "X100",
    route: "/x100",
    icon: "x100",
    key: "x100",
    sort: 8,
  },
  {
    title: "Mines",
    route: "/mines",
    icon: "mines",
    key: "mines",
    sort: 11,
  },
  {
    title: "Hotline",
    route: "/hotline",
    icon: "hotline",
    key: "hotline",
    sort: 1,
  },
  {
    title: "Popit",
    route: "/popit",
    icon: "popit",
    key: "popit",
    sort: 4,
  },
].sort((a, b) => b.sort - a.sort);

const { $auth } = useNuxtApp();
const canCreateGiveaway = computed(() => {
  return $auth.loggedIn && $auth.user.permissions.includes("giveaway:create");
});
</script>

<style lang="scss">
.ssidebar {
  background: var(--primary-200);
  width: 64px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  flex: 1;
  height: 100%;
  padding-top: 68px;

  @media screen and (max-width: 979px) {
    display: none;
  }

  &_games {
    background: var(--primary-bg);
    margin: 0 auto;
    margin-bottom: auto;
    overflow: auto;
  }

  &-nav {
    display: grid;
    gap: 8px;
    padding: 12px 0;
    color: var(--primary-300);
    text-align: center;
    border-bottom: 1px solid var(--primary-200);

    h5 {
      font-weight: 400;
      font-size: 12px;
      padding: 0 10px;
    }

    &_item {
      padding: 0 12px;

      &__link {
        width: 40px;
        height: 40px;
        border-radius: 6px;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--primary-bg);
        transition: background 0.2s;
        color: var(--primary-500);

        &:hover {
          background: var(--primary-400);
        }

        &.router-link-active {
          background: var(--primary-100);
        }
      }
    }
  }
}

.tippy-box[data-theme~="purple"] {
  background: var(--primary-400);
  color: var(--primary-800);
  font-weight: 600;

  .tippy-arrow {
    color: var(--primary-400);
  }
}
</style>
