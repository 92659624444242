<template>
  <footer class="v-footer" :class="{ withAside: showMenu }">
    <div class="v-footer_wrap" :class="{ withAside: showMenu }">
      <div class="v-footer__menu rcontainer">
        <div class="v-footer__grid">
          <div class="v-footer__grid-item">
            <div class="v-footer__grid-item-title">{{ $t("footer.casino") }}</div>
            <NuxtLink :to="localePath('/')" class="v-footer__grid-item-link">{{ $t("footer.home") }}</NuxtLink>
            <NuxtLink :to="localePath('/casino/live')" class="v-footer__grid-item-link">Live games </NuxtLink>
            <NuxtLink :to="localePath('/casino/slots')" class="v-footer__grid-item-link">Slots</NuxtLink>
          </div>
          <div class="v-footer__grid-item">
            <div class="v-footer__grid-item-title">{{ $t("footer.give") }}</div>
            <NuxtLink :to="localePath('/bonus')" class="v-footer__grid-item-link">{{ $t("header.bonuses") }}</NuxtLink>
            <NuxtLink :to="localePath('/tournaments')" class="v-footer__grid-item-link">{{ $t("header.tournaments") }}</NuxtLink>
            <NuxtLink :to="localePath('/refs')" class="v-footer__grid-item-link">{{ $t("header.referral") }}</NuxtLink>
          </div>
          <div class="v-footer__grid-item">
            <div class="v-footer__grid-item-title">{{ $t("header.support") }}</div>
            <NuxtLink :to="localePath('/fairness')" class="v-footer__grid-item-link">{{ $t("footer.fair") }}</NuxtLink>
            <NuxtLink :to="localePath('/faq')" class="v-footer__grid-item-link">FAQ</NuxtLink>
            <NuxtLink :to="localePath('/support')" class="v-footer__grid-item-link">{{ $t("footer.support") }}</NuxtLink>
          </div>
          <div class="v-footer__grid-item">
            <div class="v-footer__grid-item-title">{{ $t("footer.about") }}</div>
            <NuxtLink :to="localePath('/terms')" class="v-footer__grid-item-link">{{ $t("footer.terms") }} </NuxtLink>
            <NuxtLink :to="localePath('/policy')" class="v-footer__grid-item-link">{{ $t("footer.policy") }} </NuxtLink>
            <NuxtLink :to="localePath('/responsible-gambling')" class="v-footer__grid-item-link">{{ $t("footer.responsibility") }} </NuxtLink>
            <NuxtLink :to="localePath('/aml-policy')" class="v-footer__grid-item-link">AML policy</NuxtLink>
          </div>
          <div class="v-footer__grid-item">
            <div class="v-footer__grid-item-title">{{ $t("footer.lang") }}</div>
            <VLanguage class="v-footer__grid-lang" />
          </div>
          <div class="v-footer__grid-item">
            <div class="v-footer__grid-item-title">{{ $t("footer.social") }}</div>
            <div class="v-footer__grid-socials">
              <a target="_blank" :href="`https://vk.com/public${$config.public.VK_GROUP}`" class="v-footer__grid-social">
                <img src="@/assets/img/footer/vk.svg?url" alt="" />
              </a>
              <a target="_blank" :href="`https://t.me/${$config.public.TG_GROUP}`" class="v-footer__grid-social">
                <img src="@/assets/img/footer/tg.svg?url" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="v-footer__methods">
        <div class="v-footer__methods-grid rcontainer">
          <img src="@/assets/svg/footer/visa-icon.svg?url" class="v-footer__methods-item" alt="" />
          <img src="@/assets/svg/footer/mc-icon.svg?url" class="v-footer__methods-item" alt="" />
          <img src="@/assets/svg/footer/spb-icon.svg?url" class="v-footer__methods-item square" alt="" />
          <img src="@/assets/svg/footer/piastrix-icon.svg?url" class="v-footer__methods-item square" alt="" />
          <img src="@/assets/svg/footer/fk_wallet-icon.svg?url" class="v-footer__methods-item square" alt="" />
          <img src="@/assets/svg/footer/dash.svg?url" class="v-footer__methods-item square" alt="" />
          <img src="@/assets/svg/footer/trx.svg?url" class="v-footer__methods-item square" alt="" />
          <img src="@/assets/svg/footer/trx-1.svg?url" class="v-footer__methods-item square" alt="" />
          <img src="@/assets/svg/footer/trx-2.svg?url" class="v-footer__methods-item square" alt="" />
          <img src="@/assets/svg/footer/ltc.svg?url" class="v-footer__methods-item square" alt="" />
          <img src="@/assets/svg/footer/eth.svg?url" class="v-footer__methods-item square" alt="" />
          <img src="@/assets/svg/footer/trx-3.svg?url" class="v-footer__methods-item square" alt="" />
          <img src="@/assets/svg/footer/usdt.svg?url" class="v-footer__methods-item square" alt="" />
          <img src="@/assets/svg/footer/trx-4.svg?url" class="v-footer__methods-item square" alt="" />
          <img src="@/assets/svg/footer/btc.svg?url" class="v-footer__methods-item square" alt="" />
        </div>
      </div>
      <div class="v-footer__bottom rcontainer">
        <div class="v-footer__bottom-row">
          <VLogo :crown="false" class="v-footer__bottom-row-logo" />
          <div v-if="isCasino" id="anj-25a7c14b-cf04-4b6d-b846-524e39777770" data-anj-seal-id="25a7c14b-cf04-4b6d-b846-524e39777770" data-anj-image-size="128" data-anj-image-type="basic-small"></div>
          <a v-else class="license-link" href="/anjouan-license.pdf" target="_blank"><img src="/anjouan-license.png" width="100%" height="100%" ALT="License: Click to Validate" /></a>

          <img src="@/assets/svg/footer/18plus.svg?url" class="" alt="" />
        </div>
        <div class="v-footer__bottom-desc">
          This website is owned and operated by Novaspin N.V., a company registered and established under the laws of Curaçao, with registration number 163071, with registered address Zuikertuintjeweg
          Z/N (Zuikertuin Tower), Curaçao.
        </div>
        <div class="v-footer__bottom-trix">© {{ new Date().getFullYear() }} Trix</div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const { $auth } = useNuxtApp();

defineProps({
  showMenu: {
    type: Boolean,
    default: false,
  },
});

useHead({
  script: [
    {
      src: "https://25a7c14b-cf04-4b6d-b846-524e39777770.snippet.anjouangaming.org/anj-seal.js",
      type: "text/javascript",
    },
  ],
});

const isCasino = window.location.host === "trix.casino";

onMounted(() => {
  nextTick(() => {
    if (isCasino) window?.anj_25a7c14b_cf04_4b6d_b846_524e39777770?.init();
  });
});

const localePath = useLocalePath();
</script>

<style lang="scss">
.v-footer {
  background: #e8e5f8;
  transition: padding 0.2s;
  padding: 0 0 0 0;
  width: 100%;
  margin-top: 40px;

  @media screen and (min-width: 1350px) and (max-width: 1900px) {
    padding-left: 64px;
  }

  &.withAside {
    @media screen and (min-width: 1350px) and (max-width: 1900px) {
      padding-left: 290px;
    }
  }

  @media screen and (max-width: 1349px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &_wrap {
    padding: 29.5px 0px;
  }

  &__menu {
    margin-bottom: 32px !important;
  }

  &__methods {
    background: #f4f2ff;
    padding: 20px 0px !important;
    z-index: 1;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 300%;
      height: 100%;
      z-index: -1;
      background: inherit;
    }

    &-item {
      height: 32px;

      &.square {
        width: 32px;
      }
    }

    &-grid {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 0px;
      flex-wrap: wrap;
      row-gap: 24px;

      @media screen and (min-width: 360px) {
        gap: 6px;
      }

      @media screen and (min-width: 768px) {
        gap: 32px;
        row-gap: 16px;
      }

      @media screen and (min-width: 1024px) {
        gap: 20px;
      }

      @media screen and (min-width: 1440px) {
        gap: 32px;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    padding-top: 32px !important;

    #anj-25a7c14b-cf04-4b6d-b846-524e39777770,
    .license-link {
      width: 50px;

      img {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: 1280px) {
        width: 36px;
      }
    }

    &-row {
      display: flex;
      margin: 0 auto;
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 32px;

      &-logo {
        margin-top: 0;

        .v-logo {
          &__circle {
            box-shadow: 0px 5.12px 14.08px rgba(118, 83, 255, 0.3);
            overflow: hidden;
          }

          &__domain {
            box-shadow: 0px 5.12px 14.08px rgba(220, 150, 14, 0.3);
          }
        }

        @media screen and (max-width: 1280px) {
          .v-logo {
            &__circle {
              height: 32px;
              width: 32px;
              border-radius: 4.26667px;
              font-size: 16px;
              line-height: 1;
            }

            &__host {
              font-weight: 700;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: -0.01em;
            }

            &__domain {
              font-weight: 700;
              font-size: 9.6px;
              line-height: 12px;
            }
          }
        }
      }

      &-curacao {
        height: 39px;
        width: auto;
        aspect-ratio: 150/50;

        @media screen and (max-width: 1280px) {
          height: 32px;
        }

        &.link {
          img {
            width: 100%;
            height: 100%;
          }
        }

        &.iframe {
          height: 50px !important;

          @media screen and (max-width: 1280px) {
            transform: scale(0.64);
          }
        }
      }
    }

    &-desc {
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      text-align: center;
      color: var(--primary-800);
      margin: 0 auto;
      margin-bottom: 16px;
      width: 724px;
      max-width: 100%;
    }

    &-trix {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: var(--primary-800);
    }
  }

  &__grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 22px;
    flex-wrap: wrap;
    row-gap: 32px;

    &-socials {
      display: flex;
    }

    &-social {
      background: radial-gradient(73.33% 73.33% at 50% 50%, #9db1ff 0%, #6d3eff 100%);
      width: 40px;
      height: 40px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      & + & {
        margin-left: 12px;
      }
    }

    @media screen and (min-width: 360px) {
      column-gap: 6px;
    }

    @media screen and (min-width: 768px) {
      column-gap: 32px;
    }

    @media screen and (min-width: 1024px) {
      column-gap: 20px;
      row-gap: 16px;
    }

    @media screen and (min-width: 1440px) {
      column-gap: 32px;
    }

    &-lang {
      flex: 1;
      min-width: 140px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      width: 48%;

      @media screen and (min-width: 768px) {
        width: 30%;
      }

      @media screen and (min-width: 1024px) {
        width: auto;
      }

      &:nth-child(1) {
        order: 1;

        @media screen and (min-width: 1280px) {
          order: 1;
        }
      }

      &:nth-child(2) {
        order: 2;

        @media screen and (min-width: 1280px) {
          order: 2;
        }
      }

      &:nth-child(3) {
        order: 3;

        @media screen and (min-width: 768px) {
          order: 5;
        }

        @media screen and (min-width: 1280px) {
          order: 3;
        }
      }

      &:nth-child(4) {
        order: 4;

        @media screen and (min-width: 1280px) {
          order: 4;
        }
      }

      &:nth-child(5) {
        order: 5;

        @media screen and (min-width: 768px) {
          order: 3;
        }

        @media screen and (min-width: 1280px) {
          order: 5;
        }
      }

      &:nth-child(6) {
        order: 6;

        @media screen and (min-width: 1280px) {
          order: 6;
        }
      }

      &-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: var(--primary-800);
        margin-bottom: 16px;
      }

      &-link {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: var(--primary-800);
        transition: color 0.2s;

        & + & {
          margin-top: 12px;
        }

        &:hover {
          color: var(--primary-600);
        }
      }
    }
  }
}

.theme__dark {
  .v-footer {
    background: #2d323e;

    &__methods {
      background: #262a34;
    }
  }
}
</style>
