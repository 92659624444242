<template>
  <div class="header-mobile">
    <NuxtLink :to="localePath('/')" class="header-mobile__logo">
      <VLogo mobile />
    </NuxtLink>
    <div v-if="$auth.loggedIn" class="header-mobile__center">
      <div class="header-mobile__center_balance">
        <p>{{ $t("header.balance") }}</p>
        <span v-if="$auth.loggedIn" class="header-mobile__center-money">
          <span v-if="isBalanceHidden">***.**</span>
          <VOdometer v-else class="" :value="parseFloat(($auth.user && $auth.user.money) || 0)" />
          <img src="/images/coin.png" class="" alt="" />
        </span>
      </div>

      <a href="#" @click.prevent="banned ? () => ({}) : $vfm.show('payment', { tab: 'deposit' })" to="/user/pay" class="header-mobile__center-payment" :class="{ disabled: banned }">
        <FontIcon icon="wallet2" size="20" />
        <span>{{ $t("header.deposit") }}</span>
      </a>
      <a href="#" @click.prevent="banned ? () => ({}) : $vfm.show('payment', { tab: 'withdraw' })" class="header-mobile__center-withdraw" :class="{ disabled: banned }">
        <FontIcon icon="minus" size="20" />
      </a>
    </div>
    <a v-if="$auth.loggedIn && $auth.user && $auth.user.name" href="#" class="header-mobile__center-username" @click.prevent="$vfm.show('profile')">
      {{ $auth.loggedIn && $auth.user.name && $auth.user.name.toString().slice(0, 2) }}
    </a>
    <button v-else type="button" class="header-mobile__login" @click.prevent="$vfm.show('auth')">
      {{ $t("header.login") }}
    </button>
  </div>
  <header class="rheader">
    <div class="rheader__left">
      <button @click="toggleMenu" class="rheader__menu" type="button">
        <FontIcon :icon="showMenu ? 'burgeron' : 'burger'" />
      </button>
      <NuxtLink :to="localePath('/')" class="logotype">
        <VLogo class="rheader__logo v2" :mobile="isMobile" />
      </NuxtLink>
    </div>

    <div class="rheader__wrapper">
      <div class="rheader__container">
        <NuxtLink :to="localePath('/')" class="rheader__logotype">
          <VLogo class="rheader__logo v2" :mobile="isMobile" />
        </NuxtLink>
        <nav class="rheader__links">
          <template v-for="(link, idx) in links.filter((x) => !x.hidden && ($auth.loggedIn ? true : !x.auth))" :key="`header-link-${idx}`">
            <NuxtLink v-if="link.route" :to="localePath(link.route)" class="rheader__link" :class="{ mhide: link.hideMob, disabled: banned && link.banned }" exact-active-class="rheader__link--active">
              <FontIcon :icon="link.icon" size="20" />
              {{ link.text }}
              <img v-if="link.coin" class="coin" src="/images/coin.png" alt="" />
            </NuxtLink>
            <div v-else class="rheader__pre-dd">
              <button @click="showMore = !showMore" :class="{ 'rheader__link--active': showMore }" type="button" class="rheader__link">
                <FontIcon :icon="link.icon" size="20" />
                {{ link.text }}
              </button>
              <div class="rheader__dd" v-if="showMore" v-click-outside="() => (showMore = false)">
                <NuxtLink
                  v-for="(link, idx) in link.children"
                  :key="`header-link-${idx}`"
                  :to="localePath(link.route)"
                  class="rheader__link"
                  :class="{ mshow: link.showMob }"
                  exact-active-class="rheader__link--active"
                >
                  <FontIcon :icon="link.icon" size="20" />
                  {{ link.text }}
                  <img v-if="link.coin" class="coin" src="/images/coin.png" alt="" />
                </NuxtLink>
              </div>
            </div>
          </template>
        </nav>

        <template v-if="$auth.loggedIn">
          <div class="rheader__balance">
            <div class="rheader__balance-title">
              {{ $t("header.balance")
              }}<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.6868 0H2.24099C1.5157 0 0.927734 0.587626 0.927734 1.3125V15.75C0.927734 16.4749 1.5157 17.0625 2.24099 17.0625H16.6868C17.4121 17.0625 18 16.4749 18 15.75V1.3125C18 0.587626 17.4121 0 16.6868 0Z"
                  fill="#F8F6FF"
                />
                <path
                  d="M9.02268 11.6287C9.26642 11.8738 9.66171 11.8738 9.90545 11.6287L13.8776 7.63405C14.1214 7.38892 14.1214 6.99148 13.8776 6.74634C13.6339 6.50122 13.2386 6.50122 12.9949 6.74634L9.46406 10.2971L5.93325 6.74634C5.68951 6.50122 5.29422 6.50122 5.05048 6.74634C4.80674 6.99148 4.80674 7.38892 5.05048 7.63405L9.02268 11.6287ZM8.83987 9.78994V11.1848H10.0883V9.78994H8.83987Z"
                  fill="#D8D2EA"
                />
              </svg>
            </div>
            <div class="rheader__balance-amount">
              <span v-if="isBalanceHidden">***.**</span>
              <VOdometer v-else class="" :value="parseFloat(($auth.user && $auth.user.money) || 0)" />
              <img src="/images/coin.png" class="" alt="" />
            </div>
          </div>
          <button type="button" class="rheader__deposit" @click="banned ? () => ({}) : $vfm.show('payment', { tab: 'deposit' })" :disabled="banned">
            <span class="text">{{ $t("header.deposit") }}</span>
            <span class="sym"
              ><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.12833 5.00944H10.684C11.2362 5.00944 11.684 5.45715 11.684 6.00944V6.18253C11.684 6.73481 11.2362 7.18253 10.684 7.18253H7.12833V10.7382C7.12833 11.2904 6.68061 11.7382 6.12833 11.7382H5.95524C5.40295 11.7382 4.95524 11.2904 4.95524 10.7382V7.18253H1.42578C0.873497 7.18253 0.425781 6.73481 0.425781 6.18253V6.00943C0.425781 5.45715 0.873497 5.00944 1.42578 5.00944H4.95524V1.47998C4.95524 0.927696 5.40295 0.47998 5.95524 0.47998H6.12833C6.68061 0.47998 7.12833 0.927696 7.12833 1.47998V5.00944Z"
                  fill="#29254D"
                />
              </svg>
            </span>
          </button>
          <button type="button" class="rheader__withdraw" @click="banned ? () => ({}) : $vfm.show('payment', { tab: 'withdraw' })" :disabled="banned">
            <span class="text">{{ $t("header.withdraw") }}</span>
            <span class="sym"
              ><svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1Z" fill="#D8D2EA" />
              </svg>
            </span>
          </button>
          <button type="button" class="rheader__avatar" @click="$vfm.show('profile')">
            {{ $auth.user && $auth.user.name && $auth.user.name.slice(0, 2) }}
          </button>
          <div type="button" class="rheader__userinfo" @click="$vfm.show('profile')">
            <div class="rheader__userinfo-hello">{{ $t("header.hello") }},</div>
            <div class="rheader__userinfo-name">{{ $auth.user && $auth.user.name }}</div>
          </div>
        </template>
        <button v-else type="button" class="rheader__deposit auth" @click="$vfm.show('auth')">
          {{ $t("header.login") }}
        </button>
      </div>
    </div>
  </header>
  <ModalAuth />
</template>

<script setup>
import { useGameTournamentsStore } from "@/store/gameTournaments";
import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";
import { $vfm } from "vue-final-modal";
import IconCasino from "@/assets/svg/casino.svg?component";

const storeTour = useGameTournamentsStore();
const store = useStore();
const { bank } = storeToRefs(storeTour);
const { is_collected, deposit } = storeToRefs(store);

const route = useRoute();
const localePath = useLocalePath();

const isBalanceHidden = computed(() => {
  return route.name.includes("casino-game-id");
});

const { $auth } = useNuxtApp();

const banned = computed(() => {
  return $auth.user?.bans?.find((el) => el.type === "self:exclusion");
});

const { t } = useI18n();

const links = computed(() => {
  return [
    {
      text: t("header.home"),
      route: "/",
      icon: "casino",
    },
    {
      text: t("header.tournaments"),
      route: "/tournaments",
      icon: "cup",
    },
    {
      text: "FAQ",
      route: "/faq",
      icon: "faq-mono",
      hidden: $auth.loggedIn,
    },
    {
      text: t("header.bonuses"),
      route: "/bonus",
      auth: true,
      banned: true,
      icon: "bonus-mono",
    },
    {
      text: t("header.more"),
      auth: true,
      show: false,
      icon: "dots",
      children: [
        {
          text: "FAQ",
          icon: "faq-mono",
          route: "/faq",
        },
        {
          text: t("header.support"),
          route: "/support",
          icon: "support-mono",
        },
        {
          text: t("header.referral"),
          route: "/refs",
          auth: true,
          icon: "referral-mono",
        },
      ],
    },
  ];
});

const props = defineProps({
  showMenu: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["toggle-menu"]);

const showMore = ref(false);

function toggleMenu() {
  emit("toggle-menu", !props.showMenu);
}

const clientWidth = ref(window.innerWidth);

function onResize() {
  clientWidth.value = window.innerWidth;
}

onMounted(() => {
  window.addEventListener("resize", onResize);
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", onResize);
});

const isMobile = computed(() => {
  return clientWidth.value <= 980;
});

watch(
  () => route.path,
  () => {
    showMore.value = false;
  }
);
</script>

<script>
import { directive as outside } from "click-outside-vue3";

export default {
  directives: {
    clickOutside: outside,
  },
};
</script>

<style lang="scss">
.rcontainer.content {
  @media screen and (max-width: 980px) {
    padding-top: 12px;
  }
}

.rheader {
  display: none;

  @media screen and (min-width: 980px) {
    display: flex;
  }
}

.rheader {
  height: 68px;
  background: #ffffff;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 20;

  .mshow {
    @media screen and (min-width: 1401px) {
      display: none !important;
    }
  }

  .mhide {
    @media screen and (max-width: 1400px) {
      display: none !important;
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
  }

  &__pre-dd {
    position: relative;
    margin-left: 12px;
  }

  &__dd {
    position: absolute;
    bottom: -20px;
    right: 0;
    transform: translateY(100%);
    // opacity: 0;
    background: var(--primary-bg);
    border-radius: 6px;
    box-shadow: 0px -4px 8px rgba(87, 77, 129, 0.1);
    padding: 4px;
    gap: 4px;
    display: grid;

    & > * {
      margin-left: 0 !important;
    }
  }

  &__container {
    display: flex;
    max-width: 1280px;
    width: 100%;
    padding: 0 18px;
    margin: auto;
    align-items: center;
    z-index: 2;

    @media screen and (max-width: 1900px) {
      max-width: initial;
      width: 100%;
      // padding-right: 16px;
    }
  }

  .v-logo {
    &__circle {
      width: 42px !important;
      height: 42px !important;
    }

    &__host {
      font-size: 22px !important;
      letter-spacing: -0.01em;
    }
  }

  .v-logo__container {
    position: relative !important;
    left: 0;
    top: 0 !important;
    transform: unset !important;
  }

  .rcontainer {
    padding-left: 52px;
  }

  &__logotype {
    display: flex;
    position: relative;
    flex-shrink: 0;
    width: 40px !important;
    height: 40px !important;
    z-index: 2;

    span:not(.v-logo__shadow) {
      position: unset !important;
      transform: none !important;
      width: 40px !important;
      height: 40px !important;

      .v-logo {
        width: 100% !important;
      }

      .v-logo__circle {
        margin-right: 0 !important;
      }
    }

    @media screen and (min-width: 980px) {
      display: none;
    }
  }

  @media screen and (max-width: 980px) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 980px) {
    height: 70px;

    .logotype {
      display: none;
    }
  }

  &__logo {
    margin: 0 !important;

    @media screen and (max-width: 980px) {
      transform: translateY(-50%) scale(0.8);
      transform-origin: left center;
      left: 5px;
    }
  }

  &__menu {
    margin: 0 14px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 13px;

    @media screen and (min-width: 980px) {
      background: transparent;
      color: var(--primary-300);
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      padding: 0;
    }
  }

  .rcontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  &__left {
    max-width: 310px;
    min-width: 310px;
    flex: 1;
    display: inline-flex;
    align-items: center;
    z-index: 2;

    @media screen and (min-width: 1901px) {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }

  &__links {
    display: flex;

    @media screen and (max-width: 1270px) {
      display: none;
    }
  }

  &__link {
    padding: 11px 17px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #b6b0d7;
    display: flex;
    align-items: center;
    background: transparent;

    svg {
      margin-right: 10px;
    }

    .coin {
      width: 20px;
      margin-left: 5px;
      height: 20px;
    }

    & + & {
      margin-left: 12px;
    }

    position: relative;
    transition: all 0.2s;

    &--active {
      background: var(--primary-100);
      color: var(--primary-800);
    }

    &:hover {
      color: var(--primary-800);
    }
    &.disabled {
      cursor: no-drop;
      color: #b6b0d7;
    }
  }

  &__dark {
    background: #faf9ff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin-left: 25px;
    flex-shrink: 0;

    @media screen and (max-width: 980px) {
      display: none;
    }
  }

  &__userinfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 12px;
    background: transparent;

    @media screen and (max-width: 980px) {
      display: none;
    }

    &-hello {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3afc9;
    }

    &-name {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #3a3e46;
      max-width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__avatar {
    width: 42px;
    height: 42px;
    background: linear-gradient(90deg, #ffc658 -18.18%, #f4a100 49.18%, #ffc658 120%);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    flex-shrink: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;

    color: #2c3034;

    @media screen and (max-width: 980px) {
      width: 40px;
      height: 40px;
      border-radius: 4px;
    }

    img {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 980px) {
      margin-left: 6px;
      display: none;
    }
  }

  &__withdraw {
    background: #faf9ff;
    border-radius: 6px;
    height: 42px;
    width: 100px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #b4aed4;
    margin-left: 10px;
    flex-shrink: 0;

    @media screen and (max-width: 980px) {
      margin-left: 4px;
      margin-right: auto;
    }
  }

  &__deposit {
    border-radius: 6px;
    height: 42px;
    width: 133px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #2c3034;
    background: linear-gradient(90deg, #f4a202 0%, #fac257 100%);
    margin-left: 12px;
    flex-shrink: 0;

    &.auth {
      margin-left: auto;
    }

    @media screen and (max-width: 980px) {
      margin-left: 10px;
    }
  }

  &__deposit,
  &__withdraw {
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      display: inline;
    }

    .sym {
      display: none;
    }
    &:disabled {
      opacity: 0.5 !important;
      cursor: no-drop;
    }

    @media screen and (max-width: 980px) {
      .text {
        display: none;
      }

      .sym {
        display: flex;
      }

      &:not(.auth) {
        width: 40px;
        height: 40px;
        border-radius: 4px;
      }
    }
  }

  &__balance {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    align-items: flex-end;
    flex-wrap: nowrap;
    white-space: nowrap;

    &-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #b8b3d1;
      display: flex;
      align-items: center;
      margin-bottom: 2px;

      svg {
        margin-left: 6px;
      }

      @media screen and (max-width: 980px) {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        align-items: flex-end;

        svg {
          width: 18px;
          height: 18px;
          margin-left: 4px;
        }
      }
    }

    &-amount {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      // letter-spacing: 0.01em;
      color: #312c48;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 17px;
        height: 17px;
        margin-left: 6px;
      }

      @media screen and (max-width: 980px) {
        font-weight: 700;
        font-size: 18px;
        line-height: 17px;

        img {
          width: 14px;
          height: 14px;
          margin-left: 4px;
        }
      }
    }
  }
}
.header-mobile__center-payment,
.header-mobile__center-withdraw {
  &.disabled {
    opacity: 0.5 !important;
    cursor: no-drop;
  }
}
</style>
