<template>
  <main>
    <div id="root">
      <div class="app">
        <div class="row">
          <aside class="app_sidebar">
            <TransitionGroup v-if="clientWidth >= 1350" :name="'sidebar'">
              <AppMobileMenu :show-menu="showMenu" @show-menu="showMenu = !showMenu" v-if="showMenu" />
              <AppNavbar :show-menu="showMenu" @show-menu="showMenu = !showMenu" v-if="!showMenu" />
            </TransitionGroup>
            <AppMobileMenu v-else :show-menu="showMenu" @show-menu="showMenu = !showMenu" />
          </aside>
          <AppHeader :show-menu="showMenu" @toggle-menu="onToggleMenu" />
          <div class="app_main" :class="{ withAside: showMenu }">
            <div class="rcontainer content">
              <slot></slot>
            </div>
          </div>
          <Footer :show-menu="showMenu" />
        </div>
      </div>
    </div>
    <Teleport to="body">
      <LazyAppMobileNavigation @show-menu="showMenu = !showMenu" />
    </Teleport>
  </main>
</template>

<script>
import { $vfm } from "vue-final-modal";
import { useGameTournamentsStore } from "@/store/gameTournaments";
import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";
import { numberWithSpaces } from "../utils";
import Footer from "~~/components/App/Footer.vue";
export default {
  components: { Footer },
  setup() {
    const ddX = ref(0);
    const ddY = ref(0);
    const showMenu = ref(false);

    const store = useGameTournamentsStore();
    const { bank } = storeToRefs(store);

    const storeIndex = useStore();
    const { dark, snow } = storeToRefs(storeIndex);

    function onToggleMenu(e) {
      showMenu.value = e;
    }

    watch(
      () => showMenu.value,
      (val) => {
        if (val) {
          document.body.classList.add("overflow-hidden");
        } else {
          document.body.classList.remove("overflow-hidden");
        }
      }
    );

    const onHoverBonus = (e) => {
      const btn = document.querySelector("#bonusdd");
      if (btn) {
        const rect = btn.getBoundingClientRect();
        /*const header = document
            .querySelector("#header")
            .getBoundingClientRect();*/
        ddX.value = rect.x - rect.width / 2; //- header.x;
        ddY.value = rect.y + rect.height; //- header.x;
      }
    };
    const route = useRoute();
    watch(
      () => route.name,
      () => {
        if (window.innerWidth < 980) showMenu.value = false;
      }
    );
    const htmlClass = computed(() => {
      const darkz = dark.value ? "redesign theme__dark" : "redesign";
      const snowz = false ? "snower" : "def";
      return [darkz, snowz].join(" ");
    });
    function onResize() {
      clientWidth.value = window.innerWidth;
      if (clientWidth.value < 980) showMenu.value = false;
    }

    onMounted(() => {
      window.addEventListener("resize", onResize);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", onResize);
    });

    const { t } = useI18n();
    useHead({
      title: t("meta.home.title"),
      bodyAttrs: {
        class: "h-100",
        lang: "ru",
      },
      htmlAttrs: {
        class: htmlClass,
      },
      meta: [
        {
          name: "description",
          content: useI18n().t("meta.home.description"),
        },
      ],
    });
    const modals = ref({});

    const clientWidth = ref(window.innerWidth);

    return {
      modals,
      onHoverBonus,
      ddX,
      ddY,
      showMenu,
      bank,
      numberWithSpaces,
      onToggleMenu,
      clientWidth,
    };
  },
  computed: {
    routeKey() {
      return `page-${this.$route.name}`;
    },
  },
};
</script>

<style lang="scss">
.app_main {
  transition: padding 0.2s;
  padding: 0 0 0 0;
  width: 100%;

  @media screen and (min-width: 1350px) and (max-width: 1900px) {
    padding-left: 64px;
  }

  &.withAside {
    @media screen and (min-width: 1350px) and (max-width: 1900px) {
      padding-left: 290px;
    }
  }

  @media screen and (max-width: 1349px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.new-footer {
  width: 100%;
}

.redesign {
  .app {
    padding: 0 !important;
    min-height: 100vh;

    &>.row {
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &,
  body {
    background-color: var(--primary-1);
  }
}

.rcontainer {
  max-width: 1280px;
  width: 100% !important;
  margin: 0 auto;
  padding: 0 18px;

  // @media screen and (max-width: 1630px) {
  //   max-width: calc(100% - 370px);
  // }
  @media screen and (max-width: 980px) {
    max-width: 1280px;
    padding: 0 12px;
  }
}

.fade {

  &-enter-active,
  &-leave-active {
    transition: opacity 0.2s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}

.header-row .content .gifts {
  &::after {
    background: linear-gradient(90deg, #76ac41 3.33%, #97d25b 100%) !important;
  }
}

.header {
  position: relative;
}

.header-dd {
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #b6b0d7;
  font-weight: 500;
  margin-right: 40px;
  line-height: 70px;
  font-size: 14px;
  display: inline-block;
  // position: relative;
  background: transparent;

  &__dd {
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    flex-direction: column;
    padding: 0 10px 10px 10px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 15px 85px rgb(215 211 236 / 45%);
    z-index: 100;

    &>* {
      width: 100%;
    }
  }

  &:active,
  &:hover {
    .header-dd__dd {
      display: flex;
    }
  }
}

.icon-flex {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.overflow-hidden {
  @media screen and (max-width: 760px) {
    overflow: hidden;
  }
}

.link-yellow {
  &:hover {
    color: #f3be00 !important;
  }

  display: flex !important;
  align-items: center;

  // text-shadow: 1px 2px 21px rgba(243, 190, 0, 1);
  img {
    margin-left: 5px;
    width: 20px;
    height: 20px;
  }
}

body #root .app .row .content.new {
  margin: 0 auto;
  width: 100% !important;
  max-width: 1280px !important;
}

@keyframes sidebar-in {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@media screen and (min-width: 1350px) {
  .sidebar {
    &-leave-active {
      animation: sidebar-in 0.5s;
    }

    &-enter-active {
      animation: sidebar-in 0.5s reverse;
    }
  }
}
</style>
